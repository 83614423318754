import Image from "next/image"
import Link from "next/link"
import {DrupalNode, DrupalParagraph} from "next-drupal"
import {ParagraphText} from "@/components/paragraphs";
import {IconMicrophone, IconNews} from "@tabler/icons-react";
import {absoluteUrl} from "@/lib/utils";

interface NodeDefaultPromotedProps {
  node: DrupalNode,
  showType?: boolean
}

export function NodeDefaultPromoted({ node, showType = false, ...props }: NodeDefaultPromotedProps) {

  const introduction = () => {
    let introText = ''
    if(node.type === 'node--podcast'){
      introText = node.body?.summary ?? ''
    } else if (node.type === 'node--article') {
      introText = node.field_sous_titre ?? ''
    }

    return (
      <div key={"intro"+node.id} className="text-white text-xl line-clamp-3 lg:line-clamp-6"
           dangerouslySetInnerHTML={{__html: introText}}/>
    )
  }

  const ressourceImg = node.field_media_illustration ? node.field_media_illustration.field_media_image : (node.field_image_archive ? node.field_image_archive : false)
  const imgSrc = (node.field_media_illustration && ressourceImg.image_style_uri?.rrb_large) ? ressourceImg.image_style_uri.rrb_large : absoluteUrl(ressourceImg.uri.url)

  return (
    <article {...props}>
      <div className="md:aspect-[144/55] group relative">

        {ressourceImg && (
          <figure className="mb-4 h-full overflow-hidden">
            <Image
              src={imgSrc}
              width={ressourceImg.resourceIdObjMeta.width}
              height={ressourceImg.resourceIdObjMeta.height}
              alt={ressourceImg.resourceIdObjMeta.alt}
              className="w-full h-full object-cover aspect-[9/11] md:aspect-[144/55] group-hover:scale-110 duration-200"
              priority
            />
          </figure>
        )}

        <div className="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-black/60">
          <div className="md:w-1/2 p-4 md:p-8">

            {showType && (
              <div className="text-sm w-fit flex gap-1 items-center px-2 py-1 mb-3 bg-black/50 text-white uppercase rounded backdrop-blur-sm">
                {node.type === 'node--podcast' && (<><IconMicrophone /> podcast</>)}
                {node.type === 'node--article' && (<><IconNews /> article</>)}
              </div>
            )}

            <Link href={node.path.alias} className="stretched-link">
              <h4 className="text-3xl text-foreground-dark mb-2 text-white">{node.title}</h4>
            </Link>

            <div>
              {introduction()}
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}
