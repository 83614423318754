import Image from "next/image"
import {DrupalNode} from "next-drupal"

import {absoluteUrl, formatDatetime, reloadRevive} from "lib/utils"
import {Separator} from "@/components/ui/separator";
import {
  ParagraphCitation,
  ParagraphImage,
  ParagraphText,
  ParagraphYoutube,
  ParagraphPodcast, ParagraphPostFB
} from "@/components/paragraphs";
import {
  ParagraphCitationProps,
  ParagraphImageProps,
  ParagraphPodcastProps,
  ParagraphTextProps, ParagraphYoutubeProps
} from "@/components/paragraphs/types";
import {Ads} from "@/components/ui/ads";
import {SocialShare} from "@/components/socialShare";
import Thematics from "@/components/ui/thematics";
import Container from "@/components/ui/container";
import Breadcrumbs from "@/components/breadcrumb";
import {useEffect} from "react";

interface NodeArticleProps {
  node: DrupalNode
}

export function NodeArticle({node, ...props}: NodeArticleProps) {
  const breadcrumb = [
    {label: 'Articles', href: '/articles' },
    {label: node.title}
  ];

  const ressourceImg = node.field_media_illustration ? node.field_media_illustration.field_media_image : (node.field_image_archive ? node.field_image_archive : false)
  const imgSrc = (node.field_media_illustration && ressourceImg.image_style_uri?.rrb_large) ? ressourceImg.image_style_uri.rrb_large : ressourceImg ? absoluteUrl(ressourceImg.uri.url) : false

  useEffect(() => {
    reloadRevive();
  }, []);

  return (
    <Container className="py-10">
      <div className="lg:grid grid-flow-col gap-6">
        <div className="col-span-2 lg:mb-0 mb-8">
          <article className="article-wrapper" {...props}>
            <Breadcrumbs entries={breadcrumb}/>

            <div className="md:grid md:grid-cols-6 md:gap-6">
              <div className="col-start-1 col-end-6">
                <h1 className="mb-2 text-[40px] max-md:leading-tight text-foreground-dark">{node.title}</h1>
                <p className="text-lg mb-6">{node.field_sous_titre}</p>
              </div>

              <div className="col-start-6 relative justify-self-end">
                <SocialShare node={node}/>
              </div>
            </div>

            <Separator className='mb-4 mt-3 md:mt-0'/>

            <div className="mb-4 text-foreground-light text-sm">
              {
                node.field_auteurs && node.field_auteurs.length > 0 && (
                  <span className="font-medium">
                            Par{" "}
                    {node.field_auteurs.map((auteur: DrupalNode, index: number) => (
                      <span key={auteur.id}>{index > 0 && ", "}<span
                        key={auteur.id}>{auteur.title}</span></span>))}
                    {" "}
                        </span>
                )
              }
              <span className="uppercase text-sm">{formatDatetime(node.created)}</span>
            </div>
            {ressourceImg && (
              <figure>
                <Image
                  className="w-full object-cover object-center aspect-[144/55]"
                  src={imgSrc}
                  width={ressourceImg.resourceIdObjMeta.width}
                  height={ressourceImg.resourceIdObjMeta.height}
                  alt={ressourceImg.resourceIdObjMeta.alt ? ressourceImg.resourceIdObjMeta.alt : ''}
                  priority
                />
                {ressourceImg.resourceIdObjMeta.title && (
                  <figcaption className="py-2 text-sm text-center text-gray-600">
                    {ressourceImg.resourceIdObjMeta.title}
                  </figcaption>
                )}
              </figure>
            )}

            <div className="flex flex-col gap-4 md:px-16 mt-4">
              {node.field_contenu_structure && (
                node.field_contenu_structure.map((item: ParagraphTextProps | ParagraphCitationProps | ParagraphImageProps | ParagraphPodcastProps | ParagraphYoutubeProps) =>
                  <div key={item.id}>
                    {item.type === 'paragraph--texte' && (<ParagraphText node={item}/>)}
                    {item.type === 'paragraph--citation' && (<ParagraphCitation node={item}/>)}
                    {item.type === 'paragraph--image' && (<ParagraphImage node={item}/>)}
                    {item.type === 'paragraph--content_iframe_post_fb' && (<ParagraphPostFB node={item}/>)}
                    {item.type === 'paragraph--video_youtube' && (
                      <ParagraphYoutube node={item}/>)}
                    {item.type === 'paragraph--audio_podcast' && (
                      <ParagraphPodcast node={item} title={node.title}
                                        illustration={imgSrc ? imgSrc : undefined}/>)}
                  </div>
                )
              )}

              {node.field_thematique && node.field_thematique.length > 0 && (
                <div className="mt-12">
                  <Thematics
                    thematics={node.field_thematique.length ? node.field_thematique : [node.field_thematique]}/>
                </div>
              )}
            </div>

          </article>
        </div>

        <aside>
          <div className="sticky top-28 col-span-1 text-center">
            <Ads adsId='54' dimension={'300600'} className='hidden mb-4 lg:block' />
            <Ads adsId='55' dimension={'300250'} />
          </div>
        </aside>
      </div>
    </Container>
  )
}
