import Image from "next/image"
import {DrupalNode} from "next-drupal"

import {absoluteUrl, reloadRevive, weekDayByNumber} from "lib/utils"
import {IconClockHour10} from "@tabler/icons-react";
import {SocialShare} from "@/components/socialShare";
import {CSSProperties, useEffect, useState} from "react";
import Container from "@/components/ui/container";
import {Ads} from "@/components/ui/ads";
import {drupal} from "@/lib/drupal";
import {Episodes} from "@/components/emission";
import {useSearchParams} from "next/navigation";
import Breadcrumbs from "@/components/breadcrumb";
import {format} from "date-fns";
import * as React from "react";

interface NodeEmissionProps {
  node: DrupalNode
}

interface MyCustomCSS extends CSSProperties {
  '--emission-illustration': string;
}

export function NodeEmission({node, ...props}: NodeEmissionProps) {

  const breadcrumb = [
    {label: 'Podcasts', href: '/podcasts'},
    {label: node.title}
  ];

  useEffect(() => {
    reloadRevive();
  }, []);

  const searchParams = useSearchParams()
  const queryPage = searchParams.get('page') || 1;

  const [episodes, setEpisodes] = useState<DrupalNode[]>([])

  const limit = 12 // A modifier aussi du côté drupal dans la vue
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(Number(queryPage))

  const path = node.path.alias || '';

  const [isLoading, setLoading] = useState(true)

  useEffect((() => {

    const dateJ = format(new Date(), 't')

    drupal.getView<DrupalNode[]>(
      "skazy_podcasts--listing",
      {
        params:
          {
            'views-filter': {
              field_date: dateJ,
              field_emission: node.drupal_internal__nid,
            },
            page: (page - 1),
            include: 'field_media_illustration,field_media_illustration.field_media_image,field_fichier,field_fichier.field_media_audio_file,field_image_archive'
          },
        deserialize: false,
      }
    ).then((apiRessource) => {

      // @ts-ignore
      setCount(apiRessource?.meta?.count || 0)
      setEpisodes(drupal.deserialize(apiRessource.results) as DrupalNode[] || [])
      setLoading(false)
    })

  }), [page])

  // Changement de page sur la pagination
  useEffect((() => {

    setPage(Number(queryPage));
  }), [queryPage])


  return (
    <>
      <article {...props}>
        <div
          style={{
            background: `hsl(0deg, 0%,66%) url(${absoluteUrl(node.field_media_illustration.field_media_image.uri.url)}) no-repeat center / cover`,
            backgroundBlendMode: 'screen'
          }}>
          <div className="py-12 md:py-6 text-center xl:text-start backdrop-blur-2xl">
            <Container>
              <Breadcrumbs entries={breadcrumb}/>

              <div className="xl:grid grid-flow-col gap-6">
                {node.field_media_illustration && (
                  <figure className="col-span-1 inline-block ">
                    <Image
                      src={absoluteUrl(node.field_media_illustration.field_media_image.uri.url)}
                      width={node.field_media_illustration.field_media_image.resourceIdObjMeta.width}
                      height={node.field_media_illustration.field_media_image.resourceIdObjMeta.height}
                      alt={node.field_media_illustration.field_media_image.resourceIdObjMeta.alt ? node.field_media_illustration.field_media_image.resourceIdObjMeta.alt : ''}
                      priority
                      className="max-w-[250px]"
                    />
                    {node.field_media_illustration.field_media_image.resourceIdObjMeta.title && (
                      <figcaption className="py-2 text-sm text-center text-gray-600">
                        {node.field_media_illustration.field_media_image.resourceIdObjMeta.title}
                      </figcaption>
                    )}
                  </figure>
                )}

                <div className="col-span-4">
                  <div className="xl:grid grid-flow-col gap-6">
                    <div>
                      <h1 className="mb-1 text-[32px] text-foreground-dark">{node.title}</h1>

                      {node.field_animateurs && node.field_animateurs.length > 0 && (
                        <div
                          className="mb-4 flex gap-1 items-center flex-wrap justify-center xl:justify-start">
                          {node.field_animateurs.map((animateur, i) => (
                            <span key={animateur.id}>{animateur.title}</span>
                          ))}
                        </div>
                      )}

                      {node.field_planifications && node.field_planifications.length > 0 && (
                        <div
                          className="uppercase text-neutral-700 flex gap-1 items-center flex-wrap justify-center xl:justify-start mb-4">
                          <IconClockHour10/>
                          <span>Diffusion&nbsp;:</span>
                          {" "}
                          {node.field_planifications.map((planification, index) => (
                            <span key={planification.id}>
                                                        {weekDayByNumber(planification.field_jour)}&nbsp;:&nbsp;{planification.field_heure_debut.field_heure.toString().padStart(2, 0)}H{planification.field_heure_debut.field_minute.toString().padStart(2, 0)}&nbsp;-&nbsp;{planification.field_heure_fin.field_heure.toString().padStart(2, 0)}H{planification.field_heure_fin.field_minute.toString().padStart(2, 0)}{" "}
                                                    </span>
                          ))}
                        </div>
                      )}

                      {node.body?.processed && (
                        <div
                          dangerouslySetInnerHTML={{__html: node.body?.processed}}
                          className="text-lg"
                        />
                      )}
                    </div>
                    <SocialShare node={node}/>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </article>

      <Container className="py-10">
        <div className="lg:grid grid-flow-col gap-6">
          <div className="col-span-2 lg:mb-0 mb-8">
            {episodes && <Episodes episodes={episodes} count={count} limit={limit} page={page} path={path} isLoading={isLoading}/>}
          </div>

          <aside className="col-span-1 text-center justify-self-center">
            <Ads adsId='54' dimension={'300600'} className='hidden mb-4 lg:block' />
            <Ads adsId='55' dimension={'300250'} />
          </aside>
        </div>
      </Container>
    </>
  )
}
